<template>

  <vs-table :data="companiesData" @selected="showCustomerDetails"
            :pagination="companiesData.length > maxItems ? true : false" :max-items="maxItems" search>
    <template slot="header">

      <div class="d-inline-block float-left">
        <h2 v-if="filter === 'active'">Aktivna donatorska podjetja</h2>
        <h2 v-if="filter === 'inactive'">Neaktivna donatorska podjetja</h2>
      </div>
      <vs-chip class="mt-1 ml-3">{{companiesData.length}}</vs-chip>

    </template>
    <template slot="thead">
      <vs-th>
        <!-- empty -->
      </vs-th>
      <vs-th>
        Naziv podjetja
      </vs-th>
      <vs-th>
        Prikazno ime
      </vs-th>
      <vs-th sort-key="donor_type_id">
        Tip donatorja
      </vs-th>
      <vs-th>
        Veljavnost pogodbe
      </vs-th>
      <vs-th>
        Akcije
      </vs-th>

    </template>

    <template slot-scope="{data}">
      <vs-tr :key="index" v-for="(tr, index) in data" v-if="data[index].disabled === 0" :data="tr">
        <vs-td :data="data[index].company_name">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

            <div class="avatarWithStatus">
              <vs-avatar class="companyLogo" v-if="data[index].donor_logo" size="60px" :src="$globalFunctions.getImage(data[index].donor_logo)"/>
              <vs-avatar v-if="!data[index].donor_logo" size="60px" :text="data[index].company_name"/>

              <vs-avatar v-if="data[index].active === 1" size="small" class="status" color="success"
                         icon="check_circle"/>
              <vs-avatar v-if="data[index].active === 0" size="small" class="status" color="danger"
                         icon="radio_button_unchecked"/>
            </div>


          </vs-col>
        </vs-td>

        <vs-td :data="data[index].company_name">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{data[index].company_name}}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].company_display_name">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{data[index].company_display_name}}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].donor_type_name">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{data[index].donor_type_name}}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].contract_expiry_date">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
            {{data[index].contract_expiry_date ? moment(data[index].contract_expiry_date).format('DD. MM. YYYY') : '/'}}
          </vs-col>
        </vs-td>

        <vs-td :data="data[index].id">
          <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">

            <vs-button type="filled" color="primary" icon="swap_horizontal_circle" size="small"
                       class="vs-lg-12 mt-2" @click.stop="editCampaign(data[index])">Donatorske kampanije
            </vs-button>

          </vs-col>
        </vs-td>

      </vs-tr>
    </template>


  </vs-table>

</template>

<script>
  import Vue from 'vue';



  export default {
    name: 'companies-table',
    props: {
      companiesData: Array,
      filter: String
    },
    components: {

    },

    data() {
      return {
        maxItems: 500
      }
    },

    computed: {},

    mounted() {
      const _this = this;

    },

    destroyed() {
      console.log("destroyed");
      const _this = this;

    },

    beforeDestroy() {
      console.log("beforeDestroy");
      const _this = this;


    },

    methods: {

      showCustomerDetails(tr) {
        const _this = this;

        _this.$emit('showCompaniesDetails', tr);
      },

      stopTheEvent: (event) => {
        event.stopPropagation();
        event.preventDefault();
      },

      editCampaign(company) {
        const _this = this;
        console.log("companyId", company.id);

        _this.$router.push({name: 'Kampanije', params: {companyId: company.id}});
      }


    }
  }
</script>

