<template>
  <div id="companiesContainer">

    <vs-breadcrumb class="mb-5">
      <li><router-link to="/">Domov</router-link><span class="vs-breadcrum--separator">/</span></li>
      <li aria-disabled="page" class="disabled-link">Donacije<span class="vs-breadcrum--separator">/</span></li>
      <li aria-current="page" class="active">Donatorska podjetja</li>
    </vs-breadcrumb>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj novega donatorja" @click.stop="addCompany()"></vs-button>

    <div>

      <vs-tabs v-if="activeCompaniesData && inactiveCompaniesData">
        <vs-tab label="Aktivni" icon="check_circle" color="success" @click="">
          <CompaniesTable :companiesData="activeCompaniesData" :filter="'active'" @showCompaniesDetails="showCompaniesDetails" />
        </vs-tab>
        <vs-tab label="Neaktivni" icon="radio_button_unchecked" @click="">
          <CompaniesTable :companiesData="inactiveCompaniesData" :filter="'inactive'" @showCompaniesDetails="showCompaniesDetails" />
        </vs-tab>

      </vs-tabs>
    </div>



<!--    <div v-if="companiesData">-->


<!--      <vs-table :data="companiesData" @selected="showCompaniesDetails">-->
<!--        <template slot="header">-->
<!--          <vs-navbar class="nabarx">-->
<!--            <div slot="title">-->
<!--              <vs-navbar-title class="py-4">-->
<!--                Donatorska podjetja-->
<!--              </vs-navbar-title>-->
<!--            </div>-->
<!--          </vs-navbar>-->
<!--        </template>-->
<!--        <template slot="thead">-->
<!--          <vs-th>-->
<!--            &lt;!&ndash; empty &ndash;&gt;-->
<!--          </vs-th>-->
<!--          <vs-th>-->
<!--            Naziv podjetja-->
<!--          </vs-th>-->
<!--          <vs-th>-->
<!--            Prikazno ime-->
<!--          </vs-th>-->
<!--          <vs-th>-->
<!--            Tip donatorja-->
<!--          </vs-th>-->
<!--          <vs-th>-->
<!--            Veljavnost pogodbe-->
<!--          </vs-th>-->
<!--          <vs-th>-->
<!--            Akcije-->
<!--          </vs-th>-->

<!--        </template>-->

<!--        <template slot-scope="{data}">-->
<!--          <vs-tr :key="index" v-for="(tr, index) in data" v-if="data[index].disabled === 0" :data="tr">-->
<!--            <vs-td :data="data[index].company_name">-->
<!--              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">-->
<!--                <vs-avatar v-if="data[index].donor_logo" size="large" :src="$globalFunctions.getImage(data[index].donor_logo)"/>-->
<!--                <vs-avatar v-if="!data[index].donor_logo" size="large" :text="data[index].company_name"/>-->
<!--              </vs-col>-->
<!--            </vs-td>-->

<!--            <vs-td :data="data[index].company_name">-->
<!--              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">-->
<!--                {{data[index].company_name}}-->
<!--              </vs-col>-->
<!--            </vs-td>-->

<!--            <vs-td :data="data[index].company_display_name">-->
<!--              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">-->
<!--                {{data[index].company_display_name}}-->
<!--              </vs-col>-->
<!--            </vs-td>-->

<!--            <vs-td :data="data[index].donor_type_id">-->
<!--              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">-->
<!--                {{data[index].donor_type_id}}-->
<!--              </vs-col>-->
<!--            </vs-td>-->

<!--            <vs-td :data="data[index].contract_expiry_date">-->
<!--              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">-->
<!--                {{data[index].contract_expiry_date ? moment(data[index].contract_expiry_date).format('DD. MM. YYYY') : '/'}}-->
<!--              </vs-col>-->
<!--            </vs-td>-->

<!--            <vs-td :data="data[index].id">-->
<!--              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">-->

<!--                <vs-button type="filled" color="primary" icon="swap_horizontal_circle" size="small"-->
<!--                           class="vs-lg-12 mt-2" @click.stop="editCampaign(data[index])">Donatorske kampanije-->
<!--                </vs-button>-->

<!--              </vs-col>-->
<!--            </vs-td>-->

<!--          </vs-tr>-->
<!--        </template>-->
<!--      </vs-table>-->
<!--    </div>-->

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">

        <Company v-if="companyId && sidebarActive" :companyId="companyId" />
      </vs-sidebar>
    </div>

  </div>
</template>

<script>
  import Vue from 'vue';
  import Company from "../../components/Sopotniki/donations/Company";
  import CompaniesTable from "../../components/Sopotniki/donations/CompaniesTable";


  export default {
    name: 'companies',

    components: {
      Company,
      CompaniesTable
    },

    data() {
      return {
        companiesData: null,
        activeCompaniesData: null,
        inactiveCompaniesData: null,
        companyId: null,
        sidebarActive: false
      }
    },

    computed: {},

    mounted() {
      const _this = this;
      _this.getCompanies();
    },

    beforeRouteLeave(to, from, next) {
      console.log("beforeRouteLeave");
      const _this = this;
      _this.companyId = null;
      _this.sidebarActive = false;
      setTimeout(() => {next()}, 100);
    },

    methods: {
      getCompanies() {
        const _this = this;

        _this.$vs.loading();


        Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'donors')
          .then((res) => {

            _this.companiesData = res.data.data;
            _this.activeCompaniesData = _.filter(res.data.data, {active: 1});
            _this.inactiveCompaniesData = _.filter(res.data.data, {active: 0});

            _.each(_this.activeCompaniesData, function (item,key) {
              item.donor_type_name = item.donorType.name;
            });

            _.each(_this.inactiveCompaniesData, function (item,key) {
              item.donor_type_name = item.donorType.name;
            });

            console.log("activeCompaniesData: ", _this.activeCompaniesData);
            console.log("inactiveCompaniesData: ", _this.inactiveCompaniesData);


            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      showCompaniesDetails(tr) {
        const _this = this;

        console.log("HERE!", tr);

        _this.companyId = parseInt(tr.id);
        _this.sidebarActive = true;
      },

      addCompany() {
        const _this = this;

        _this.$router.push({name: 'Podjetje'})
      },

      editCampaign(company) {
        const _this = this;
        console.log("companyId", company.id);

        _this.$router.push({name: 'Kampanije', params: {companyId: company.id}});
      }

    }
  }
</script>

