<template>
  <div id="companyContainer" class="relative">

    <div class="loadingContainer">


{{companyData}}

      <div v-if="companyData">

        <vs-list>
<!--          <vs-list-item class="unit pt-5"-->
<!--                        :title="companyData.name"-->
<!--                        :subtitle="companyData.coveredMunicipalities"></vs-list-item>-->

<!--          <vs-list-header icon="person" title="Koordinator enote" color="info"></vs-list-header>-->
<!--          <vs-list-item :title="companyData.volunteers.first_name + ' ' + companyData.volunteers.last_name"-->
<!--                        :subtitle="companyData.volunteers.notes">-->
<!--            <template slot="avatar">-->
<!--              <vs-avatar :src="$globalFunctions.getUserAvatar(companyData.volunteers.profile_image)"/>-->
<!--            </template>-->
<!--          </vs-list-item>-->

<!--          <vs-list-header icon="person" title="Skrbnik enote" color="info" v-if="unitManager"></vs-list-header>-->
<!--          <vs-list-item v-if="unitManager" :title="unitManager.first_name + ' ' + unitManager.last_name">-->
<!--            <template slot="avatar">-->
<!--              <vs-avatar :src="$globalFunctions.getUserAvatar(unitManager.profile_image)"/>-->
<!--            </template>-->
<!--          </vs-list-item>-->

<!--          <vs-list-header icon="home" title="Kontaktni podatki enote" color="info"></vs-list-header>-->
<!--          <vs-list-item :title="companyData.addresses.quick_search" icon="location_on"></vs-list-item>-->
<!--          <vs-list-item :title="companyData.phone_reservations" :subtitle="'Telefonska številka za rezervacije'"-->
<!--                        icon="call"></vs-list-item>-->
<!--          <vs-list-item :title="companyData.phone_volunteer" :subtitle="'Telefonska številka prostovoljskega telefona'"-->
<!--                        icon="add_ic_call"></vs-list-item>-->
<!--          <vs-list-item :title="companyData.mail_recipients ? companyData.mail_recipients : 'ni dodatnih prejemnikov'"-->
<!--                        :subtitle="'E-naslovi dodatnih prejemnikov potnih nalogov'" icon="email"></vs-list-item>-->

<!--          <vs-list-header icon="directions_car" :title="companyData.cars.length > 1 ? 'Vozila' : 'Vozilo'"-->
<!--                          color="info"></vs-list-header>-->


<!--          <vs-list-item v-for="(car, index) in companyData.cars" :key="'car_' + index" :title="car.name"-->
<!--                        :subtitle="car.registrationNumber">-->
<!--            <template slot="avatar">-->
<!--              <vs-avatar :style="'background-color:' + car.color" :text="car.name"/>-->
<!--            </template>-->
<!--          </vs-list-item>-->
<!--          -->


        </vs-list>


        <vs-row
          vs-align="center"
          vs-type="flex" vs-justify="space-around" vs-w="12" class="mt-2">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button color="primary" icon="edit" @click="editCompany()">Uredi</vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
            <vs-button color="danger" icon="delete">Izbriši</vs-button>
          </vs-col>
        </vs-row>


      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import DirectionsRenderer from "../../../helpers/DirectionsRenderer";

  export default {
    name: 'reservations-calendar',

    components: {
      DirectionsRenderer
    },
    props: {
      companyId: Number
    },
    data() {
      return {
        companyData: null
      }
    },

    computed: {},

    mounted() {
      const _this = this;


      _this.getCompany();

    },

    watch: {
      companyId: {
        handler(val, oldVal) {
          console.log("CHANGE!!!!");
          this.companyData = null;


          if (val) {
            this.getCompany();
          }
        }
      }
    },

    methods: {
      getCompany() {
        const _this = this;


        this.$vs.loading({
          container: '#companyContainer .loadingContainer',
        })


        Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'donors/' + _this.companyId)
          .then((res) => {

            _this.companyData = res.data.data; //_.filter(res.data.data, {id: _this.companyId})[0];
            console.log("TOLE: ", _this.companyData);


            _this.$vs.loading.close('#companyContainer .loadingContainer > .con-vs-loading');


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close('#companyContainer .loadingContainer > .con-vs-loading');
            return false
          });
      },

      editCompany() {
        const _this = this;

        _this.$router.push({name: 'Podjetje', params: {companyId: _this.companyId}});
      }

    }
  }
</script>

